import { useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
// import { Pagination, Autoplay, Navigation } from "swiper";
// css
import "./team.scss";
// images
import VideoThumb from "./img/team.webp";
import PalyBtn from "./img/btn.svg";
import PauseBtn from "./img/pause.svg";
// import GallImg1 from "./img/gallery/gall1.webp";
// import GallImg2 from "./img/gallery/gall2.webp";
// import GallImg3 from "./img/gallery/gall3.webp";
// import GallImg4 from "./img/gallery/gall4.webp";
import FiVideo from "./img/gallery/Fusion-Innovative.mp4";

// Components
import TeamReview from "./owl-team";
const Team = () => {
  return (
    <>
      <main id="team-page">
        <section>
          <Container>
            <Row>
              <Col xxl={12}>
                <div className="heading">
                  <h1>Meet Our Team</h1>
                  <hr />
                  <h4>Brains Behind Our Success!</h4>
                  <p>
                    Our multi-disciplinary team focuses on building expertised
                    products, <br /> managing and operating to deliver amazing
                    results.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <TeamReview />
        <VideoSection />
        {/* <GallerySection /> */}
      </main>
    </>
  );
};
export default Team;

function VideoSection(params) {
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [setVideoTime] = useState(0);
  const videoHandler = (control) => {
    if (control === "play") {
      videoRef.current.play();
      setPlaying(true);
      var vid = document.getElementById("video1");
      setVideoTime(vid.duration);
    } else if (control === "pause") {
      videoRef.current.pause();
      setPlaying(false);
    }
  };
  return (
    <>
      <section className="video-section">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={10}>
              <div className="inside">
                <video poster={VideoThumb} id="video1" ref={videoRef}>
                  <source src={FiVideo} />
                </video>

                {playing ? (
                  <img
                    onClick={() => videoHandler("pause")}
                    className="video-btn"
                    alt=""
                    src={PauseBtn}
                  />
                ) : (
                  <img
                    onClick={() => videoHandler("play")}
                    className="video-btn"
                    alt=""
                    src={PalyBtn}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

// function VideoModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Modal heading
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <h4>Centered Modal</h4>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// function VideoModalBtn() {
//   const [modalShow, setModalShow] = useState(false);

//   return (
//     <>
//       <Button className="video-btn" onClick={() => setModalShow(true)}>
//         <Image src={PalyBtn}></Image>
//       </Button>
//       <VideoModal show={modalShow} onHide={() => setModalShow(false)} />
//     </>
//   );
// }
// function GallerySection() {
//   const GalleryImgs = [
//     {
//       title: "Fusion Innovative Gallery 1",
//       img: GallImg1,
//     },
//     {
//       title: "Fusion Innovative Gallery 2",
//       img: GallImg2,
//     },
//     {
//       title: "Fusion Innovative Gallery 3",
//       img: GallImg3,
//     },
//     {
//       title: "Fusion Innovative Gallery 4",
//       img: GallImg4,
//     },
//   ];
//   return (
//     <>
//       <section className="gallery-section">
//         <Container fluid>
//           <Row>
//             <Col xxl={12}>
//               <h3 className="gallery-title">Our Gallery</h3>
//             </Col>
//             <Col xxl={12}>
//               <Swiper
//                 slidesPerView={6}
//                 spaceBetween={30}
//                 centeredSlides={true}
//                 grabCursor={true}
//                 loop={true}
//                 navigation={true}
//                 pagination={{
//                   clickable: true,
//                 }}
//                 autoplay={{
//                   delay: 2500,
//                   disableOnInteraction: true,
//                 }}
//                 speed={2000}
//                 modules={[Pagination, Autoplay, Navigation]}
//                 breakpoints={{
//                   0: {
//                     slidesPerView: 1,
//                     spaceBetween: 20,
//                   },
//                   421: {
//                     slidesPerView: 2,
//                     spaceBetween: 10,
//                   },
//                   576: {
//                     slidesPerView: 3,
//                     spaceBetween: 20,
//                   },
//                   768: {
//                     slidesPerView: 3,
//                     spaceBetween: 20,
//                   },
//                   992: {
//                     slidesPerView: 4,
//                     spaceBetween: 20,
//                   },
//                   1200: {
//                     slidesPerView: 5,
//                     spaceBetween: 20,
//                   },
//                   1400: {
//                     slidesPerView: 6,
//                     spaceBetween: 20,
//                   },
//                 }}
//                 className="gallery-swipe"
//               >
//                 {GalleryImgs.map((GalleryImg, index) => (
//                   <SwiperSlide key={index}>
//                     <Image src={GalleryImg.img} fluid />
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </>
//   );
// }
