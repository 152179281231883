import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";

// images
import CEOImg from "./img/ceo.png";
import COOImg from "./img/coo.png";
import VPImg from "./img/vp.png";

export default function TeamReview() {
  const TeamDetails = [
    {
      img: CEOImg,
      name: "Hari Prasad",
      desig: "CEO & Founder",
      quotes: (
        <>The World is Full of Ideas, if you Don't do it Someone Else Will</>
      ),
    },
    {
      img: COOImg,
      name: "Shanthi Priya",
      desig: "Chief Operating Officer",
      quotes: <>Work Hard in Silence, let your Success Make the Noise</>,
    },
    {
      img: VPImg,
      name: "Ajay Shankar",
      desig: "VP Global Business",
      quotes: <>Never Give up Because Great Things Take Time</>,
    },
    {
      img: CEOImg,
      name: "Hari Prasad",
      desig: "CEO & Founder",
      quotes: (
        <>The World is Full of Ideas, if you Don't do it Someone Else Will</>
      ),
    },
    {
      img: COOImg,
      name: "Shanthi Priya",
      desig: "Chief Operating Officer",
      quotes: <>Work Hard in Silence, let your Success Make the Noise</>,
    },
    {
      img: VPImg,
      name: "Ajay Shankar",
      desig: "VP Global Business",
      quotes: <>Never Give up Because Great Things Take Time</>,
    },
  ];
  const responsive = {
    responsive: {
      0: {
        items: 1,
        margin: 15,
      },

      768: {
        items: 1,
        margin: 15,
        animateOut: "slideOutDown",
        animateIn: "flipInX",
      },

      992: {
        items: 3,
        margin: 15,
        animateOut: "slideOutDown",
        animateIn: "flipInX",
      },
      1400: {
        items: 3,
        margin: 30,
        animateOut: "slideOutDown",
        animateIn: "flipInX",
      },
    },
  };
  return (
    <>
      <Helmet>
        <title>Team</title>
      </Helmet>
      <section className="team-section">
        <Container>
          <Row>
            <Col>
              <OwlCarousel
                className="team-owl"
                loop
                margin={30}
                center={true}
                lazyLoad
                autoplay={true}
                autoplayTimeout={5000}
                smartSpeed={1500}
                lazyContent
                autoplayHoverPause
                dots={false}
                nav={false}
                {...responsive}
              >
                {/* Items Start */}
                {TeamDetails.map((TeamDetail, index) => (
                  <div class="item" key={index}>
                    <div className="team-box">
                      <div className="header">
                        <Image src={TeamDetail.img} fluid />
                        <div className="details">
                          <h5>{TeamDetail.name}</h5>
                          <p>{TeamDetail.desig}</p>
                        </div>
                      </div>
                      <div className="quotes">
                        <p>{TeamDetail.quotes}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {/* Items End */}
              </OwlCarousel>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
