import fi from "./assets/offer/budget.svg";
import cloud from "./assets/offer/cloud-computing.png";
import support from "./assets/offer/customer-support.svg";
import it from "./assets/offer/growth.svg";
import marketing from "./assets/offer/marketing.png";
import req from "./assets/offer/recruitment.png";
import code from "./assets/offer/code.png";
import design from "./assets/offer/design.png";

const Data = [
  {
    img: fi,
    title: "Finance",
    path: "Finance",
    Inner: "Inner-Finance",
  },
  {
    img: req,
    title: "Human Resource",
    path: "Hr",
    Inner: "Inner-Hr",
  },
  {
    img: it,
    title: "Sales",
    path: "Sales",
    Inner: "Inner-Sales",
  },
  {
    img: support,
    title: "Support",
    path: "Support",
    Inner: "Inner-Support",
  },
  {
    img: cloud,
    title: "IT Infrastructure",
    path: "It-Infrastructure",
    Inner: "Inner-Infrastructure",
  },
  {
    img: code,
    title: "Technology",
    path: "Developer",
    Inner: "Inner-Developer",
  },
  {
    img: marketing,
    title: "Marketing",
    path: "Marketing",
    Inner: "Inner-Marketing",
  },
  {
    img: design,
    title: "UI / UX",
    path: "Design",
    Inner: "Inner-Design",
  },
];

export default Data;
