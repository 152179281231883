import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// CSS
import "./style/notfound.css";
// images
import ErrorImg from "./images/notfound/FusionPOS-404-error.svg";
export default function Home() {
  return (
    <React.Fragment>
      <section className="errorpage">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={5} xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="errorpage-inside">
                <img src={ErrorImg} alt="FusionPOS-404-error" />
                <h3>Page Not Found</h3>
                <Link to={"/"}>Back to home</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
