import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
// scss
import "./blog.scss";
//  Images
import CEOblogImg from "./img/ceo-blog.png";
import FBIcon from "./img/social/facebook.svg";
import InstaIcon from "./img/social/instagram.svg";
import LinkedInIcon from "./img/social/linkedin.svg";
import TwitterIcon from "./img/social/twitter.svg";
import YTIcon from "./img/social/youtube.svg";

const BlogDetailPage = () => {
  const Socials = [
    {
      Name: "facebook",
      Img: FBIcon,
      Url: "https://www.facebook.com/people/Fusion-Innovative/100063749125452/",
    },
    {
      Name: "Twitter",
      Img: TwitterIcon,
      Url: "https://twitter.com/FusionInnovati2",
    },
    {
      Name: "Instagram",
      Img: InstaIcon,
      Url: "https://www.instagram.com/fusion_innovative/",
    },
    {
      Name: "Linkedin",
      Img: LinkedInIcon,
      Url: "https://in.linkedin.com/company/fusion-innovative",
    },
    {
      Name: "Youtube",
      Img: YTIcon,
      Url: "https://www.youtube.com/@fusionpos",
    },
  ];
  const BlogContents = [
    {
      Title: "1) Create the vision",
      Dis: " Founders are naturally great at this.",
    },
    {
      Title: "2) Decide the priorities",
      Dis: " This is the strategy piece.",
    },
    {
      Title: "3) Allocate resources",
      Dis: " Time, Money, People",
    },
    {
      Title: "4) Evaluate results",
      Dis: " what will you measure?",
    },
    {
      Title: "5) Hire and coach leaders",
      Dis: " 'A Players' like scorecards",
    },
    {
      Title: "6) Model the culture",
      Dis: " create clear agreements vs. unspoken expectations. When you’re the Founder and CEO, it’s easy to lose sight of your role. The line between where you end and your business begins seems blurry. But for your business to grow, these 6 things require full, focused attention. If you’re doing things that are not on this list, stop. If you can't stop, it's time to hire a professional CEO. If you don't want to stop doing some of the technical work, handling sales, or proofing proposals, ask yourself if you really want to be the CEO? There's no shame if the answer is no. You can still be the founder and the owner. But if you do want to be CEO, treat your business like an asset and make every decision based on whether it will increase the value of your business.",
    },
  ];

  return (
    <>
      <section className="blog-detail">
        <section className="main-img-section">
          <Container>
            <Row>
              <Col xxl={12}>
                <Image fluid src={CEOblogImg} />
                <div className="content">
                  <span className="by-text">
                    By
                    <Link to="/">Fusion Innovative</Link>| Dec 20, 2022
                  </span>
                  <ul>
                    {Socials.map((Social, index) => (
                      <>
                        <li key={index}>
                          <a target="_blank" rel="noreferrer" href={Social.Url}>
                            <img
                              src={Social.Img}
                              alt={"Fusion Innovative" + Social.name}
                            />
                          </a>
                        </li>
                      </>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="content-section">
          <Container>
            <Row>
              <Col xxl={12}>
                <ul>
                  {BlogContents.map((BlogContent, index) => (
                    <li key={index}>
                      <p>
                        {BlogContent.Title}
                        <span> - {BlogContent.Dis}</span>
                      </p>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </section>
    </>
  );
};
export default BlogDetailPage;
