import { Col, Container, Row } from "react-bootstrap";
import "./assets/style/style.scss";

function InnerErrorContent() {
  return (
    <section className="contact-hr">
      <Container>
        <Row>
          <Col>
            <h5 className="error-title">
              Thank you for your interest. Currently, there are no openings in
              your department which match your experience and Skill sets.
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default InnerErrorContent;
