import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";

// CSS
import "./partners.css";

// Images Imported
import pizzahut from "./img/Untitled-1-08.png";
import wagama from "./img/Untitled-1-09.png";
import papajohns from "./img/Untitled-1-05.png";
import starbucks from "./img/Untitled-1-04.png";
import subway from "./img/Untitled-1-03.png";
import macdon from "./img/Untitled-1-02.png";
import kfc from "./img/Untitled-1-01.png";
import fiveguys from "./img/Untitled-1-06.png";
import dominos from "./img/dominos.png";

const OurClients = (params) => {
  return (
    <React.Fragment>
      <Container className="trustedby">
        <Row className="justify-content-center align-items-center">
          <h3 className="home-title text-center">
            Trusted By 3000+ Clients & Partners
          </h3>
          <Col xl="7" md="7" className="text-center">
            <p className="text-center">
              We take a client firest approach to all that we do. Our
              collaborative approach means that we forge a working partnership
              that ensures we are on the same page and working together to
              achieve your objectives.
            </p>
          </Col>
        </Row>
      </Container>
      <section className="our-partners" id="client-partners">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10} md={10}>
              <div className="logo-all-first">
                <div>
                  <Image src={pizzahut} fluid />
                </div>
                <div>
                  <Image src={wagama} fluid />
                </div>
                <div>
                  <Image src={papajohns} fluid />
                </div>
                <div>
                  <Image src={starbucks} fluid />
                </div>
                <div>
                  <Image src={subway} fluid />
                </div>
              </div>
              <div className="logo-all-second">
                <div>
                  <Image src={macdon} fluid />
                </div>
                <div>
                  <Image src={kfc} fluid />
                </div>
                <div>
                  <Image src={fiveguys} fluid />
                </div>
                <div>
                  <Image src={dominos} fluid />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default OurClients;
