// *******~ Import ~******** //
// React
// Assets
import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
// Assets
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// Components
// CSS
import "./assets/style/style.scss";

import JoinIcon from "./assets/img/join-icon.svg";

import Uk from "./assets/img/Group-18493.svg";
// import Us from "./assets/img/Group-18494.svg";
import Ind from "./assets/img/Group-18495.svg";
// import Phone from "./assets/img/Group-18496.svg";
import Email from "./assets/img/Group-18497.svg";
import { AddressMap } from "../component/map/googlemap";

// *******~ Import ~******** //

const Contactus = (params) => {
  const IconsDatas = [
    {
      icon: Uk,
      text: (
        <>
          <h4>United Kingdom</h4>
          IF28, Threefield House,
          <br />
          Threefield Lane, Southampton,
          <br />
          SO14 3LP, UK
          <br />
          <strong>Call Us on</strong>
          03301225960
        </>
      ),
    },
    // {
    //   icon: Us,
    //   text: (
    //     <>
    //       <h4>United states</h4>
    //       8409 Dorsey Circle, Suite 201,
    //       <br /> Manassas, VA 20110
    //     </>
    //   ),
    // },
    {
      icon: Ind,
      text: (
        <>
          <h4>India</h4>
          Heavitree Complex 1st Floor,
          <br /> Unit 1-A ,Spurtank Road, <br />
          Chetpet,Chennai-600031 <br />
          <strong>Call Us on</strong>
          +91 4435102300
        </>
      ),
    },
  ];

  // const IconsDatas1 = [
  //   {
  //     icon: Phone,
  //     text: (
  //       <>
  //         <h4>Support</h4>
  //         <a href="tel:044-35102300" className="a-link">
  //           044-35102300
  //         </a>
  //       </>
  //     ),
  //   },
  // ];

  const IconsDatas2 = [
    {
      icon: Email,
      text: (
        <>
          <h4>Email Address</h4>
          <a href="mailto:contactus@fusioninnovative.com" className="a-link">
            contactus@fusioninnovative.com{" "}
          </a>
        </>
      ),
    },
  ];

  const [formState, setFormState] = useState({
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    contact_subject: "",
    contact_msg: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    axios
      .post("https://api.fusioninnovative.com/contactUs", formState, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status === false) {
          alert(response.data.data);
        } else {
          console.log(response.data);
          alert("Form Submitted");
          setFormState({
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            contact_subject: "",
            contact_msg: "",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
      </Helmet>
      <main className="contact-page">
        <section className="career-slide">
          <Container>
            <Row className="align-items-center">
              <Col xxl={12} lg={12}>
                <h1>Contact Us</h1>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="icon-section">
          <Container>
            <Row className="">
              <Col xxl={4} xl={4} lg={4}>
                <div className="addr-bg mtb">
                  {IconsDatas.map((IconsData, index) => (
                    <Col key={index}>
                      <div className="icon-box">
                        <div>
                          <Image src={IconsData.icon} />
                        </div>
                        <div>
                          <p>{IconsData.text}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </div>
                {/* <div className="addr-bg mtb">
                  {IconsDatas1.map((IconsData, index) => (
                    <Col key={index}>
                      <div className="icon-box">
                        <div>
                          <Image src={IconsData.icon} />
                        </div>
                        <div>
                          <p>{IconsData.text}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </div> */}
                <div className="addr-bg mtb">
                  {IconsDatas2.map((IconsData, index) => (
                    <Col key={index}>
                      <div className="icon-box">
                        <div>
                          <Image src={IconsData.icon} />
                        </div>
                        <div>
                          <p>{IconsData.text}</p>
                        </div>
                      </div>
                    </Col>
                  ))}
                </div>
              </Col>

              <Col xxl={8} xl={8} lg={8}>
                <div className="addr-bg mtb ptb-30">
                  <h4 className="mb-20">Leave A Message</h4>
                  <Form onSubmit={sendEmail}>
                    <Row className="justify-content-center">
                      <Col xxl={12} md={12}>
                        <InputGroup className="mb-4">
                          <Form.Control
                            type="text"
                            placeholder="Your Name*"
                            required
                            name="contact_name"
                            value={formState.contact_name}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col xxl={12} md={12}>
                        <InputGroup className="mb-4">
                          <Form.Control
                            type="email"
                            placeholder="Email Address*"
                            required
                            name="contact_email"
                            value={formState.contact_email}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col xxl={12} md={12}>
                        <InputGroup className="mb-4">
                          <Form.Control
                            type="text"
                            placeholder="Phone Number*"
                            required
                            name="contact_phone"
                            value={formState.contact_phone}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col xxl={12} md={12}>
                        <InputGroup className="mb-4">
                          <Form.Control
                            type="text"
                            placeholder="Subject*"
                            required
                            name="contact_subject"
                            value={formState.contact_subject}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </Col>
                      <Col xxl={12} md={12}>
                        <InputGroup className="mb-4">
                          <Form.Control
                            as="textarea"
                            rows={4}
                            placeholder="Message"
                            name="contact_msg"
                            value={formState.contact_msg}
                            onChange={handleInputChange}
                          />
                        </InputGroup>
                      </Col>

                      <Col xxl={5}>
                        <Button type="submit" className="s-btn">
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="joing-us">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xxl={12} xl={12}>
                <Heading img={JoinIcon} title="Find Us on Google Maps" />
              </Col>

              <div>
                <AddressMap />
              </div>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};
export default Contactus;

const Heading = (props) => {
  return (
    <>
      <div className="heading">
        <h3>{props.title}</h3>
        <p>{props.dis}</p>
      </div>
    </>
  );
};
