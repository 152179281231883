import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
// css
import "./press.scss";
// images
import Lj from "./img/lj.jpg";
import Bt from "./img/bt.jpg";
import Ukh from "./img/ukh.jpg";
// Components

const Press = (params) => {
  const PressDatas = [
    {
      pricon: Lj,
      prtext: (
        <>
          <span>September 15, 2022</span>
          <h4>
            Fusion Kitchen Helping Local Food Business Community To Grow Their
            Business
          </h4>
          Online food ordering websites and applications have made our life much
          easier by providing instant access to a variety of dining selections.
          <br />
          <a
            href="https://londonjournal.co.uk/2022/09/09/fusion-kitchen-helping-local-food-business-community-to-grow-their-business/"
            target="_blank"
            rel="noreferrer"
            className="r-more"
          >
            Read More
          </a>
        </>
      ),
    },
    {
      pricon: Bt,
      prtext: (
        <>
          <span>September 15, 2022</span>
          <h4>
            Fusion Kitchen Helping Local Food Business Community To Grow Their
            Business
          </h4>
          Online food ordering websites and applications have made our life much
          easier by providing instant access to a variety of dining selections.
          <br />
          <a
            href="https://birminghamtimes.uk/2022/09/09/fusion-kitchen-helping-local-food-business-community-to-grow-their-business/"
            target="_blank"
            rel="noreferrer"
            className="r-more"
          >
            Read More
          </a>
        </>
      ),
    },
    {
      pricon: Ukh,
      prtext: (
        <>
          <span>September 15, 2022</span>
          <h4>
            Fusion Kitchen Helping Local Food Business Community To Grow Their
            Business
          </h4>
          Online food ordering websites and applications have made our life much
          easier by providing instant access to a variety of dining selections.
          <br />
          <a
            href="https://ukherald.co.uk/2022/09/09/fusion-kitchen-helping-local-food-business-community-to-grow-their-business/"
            target="_blank"
            className="r-more"
            rel="noreferrer"
          >
            Read More
          </a>
        </>
      ),
    },
  ];
  return (
    <>
      <main id="press-page">
        <section>
          <Container>
            <Row>
              <Col>
                <div className="addr-bg mtb d-flex flex-dir">
                  {PressDatas.map((PressData, index) => (
                    <Col key={index}>
                      <div className="icon-box">
                        <div className="box-inside">
                          <Image src={PressData.pricon} className="pr-img" />
                          <p>{PressData.prtext}</p>
                        </div>
                        {/* <div className="box-inside">
                          <p>{IconsData.text}</p>
                        </div> */}
                      </div>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  );
};
export default Press;
