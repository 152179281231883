import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import Navigation from "./header/navbar";
import ScrollToTop from "./common/scrolltop";
//import GoTop from "./common/gototop";
import Footer from "./footer/footer";
import Routing from "./routing";
// CSS
import "./App.css";
import GoTop from "./common/gototop";
// import "./scss/App.scss";
export default function App(params) {
  return (
    <>
      {/* <Router basename={"/innovativemail"}> */}
      <Router>
        <ScrollToTop />
        <Navigation />
        {/* <main> */}
        <Routing />
        {/* </main> */}
        <Footer />
        <GoTop />
      </Router>
    </>
  );
}
