import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./timeline.scss";

// Image
import FthreeLogo from "./img/f3.svg";
import FPOSLogo from "./img/FusionPos-Logo.svg";
import FSuitLogo from "./img/fusion-suite.svg";
import FdriveLogo from "./img/fusion-drive.svg";
import FpartnerLogo from "./img/fusion-partner.svg";
import FkitchenLogo from "./img/fusion-kitchen.svg";
import FgenieLogo from "./img/fusion-genie.svg";
import FmbLogo from "./img/fmb.svg";
import FbookingLogo from "./img/fusion-booking.svg";

const TimeLine = (params) => {
  const JourneyDatas = [
    {
      Year: "2015",
      details: [
        {
          position: "even-div",
          logo: FthreeLogo,
          title: "Fusion3",
          dis: (
            <>Service-oriented company to provide digital technological needs</>
          ),
        },
      ],
    },
    {
      Year: "2017",
      details: [
        {
          position: "odd-div",
          logo: FPOSLogo,
          title: "FusionPOS",
          dis: <>Online ordering system for Hospitality sector</>,
        },
        {
          position: "even-div",
          logo: FSuitLogo,
          title: "Fusion Suite",
          dis: <>Unique and Powerful CRM to manage the entire business</>,
        },
      ],
    },
    {
      Year: "2019",
      details: [
        {
          position: "odd-div",
          logo: FdriveLogo,
          title: "Fusion Drive",
          dis: <>Driver Tracking system & Management</>,
        },
        {
          position: "even-div",
          logo: FpartnerLogo,
          title: "Fusion Partner",
          dis: <>To manage Businessnes Anytime! Anywhere!</>,
        },
        {
          position: "odd-div",
          logo: FkitchenLogo,
          title: "Fusion Kitchen",
          dis: <>UK's Local food delivery plaform</>,
        },
      ],
    },
    {
      Year: "2020",
      details: [
        {
          position: "even-div",
          logo: FgenieLogo,
          title: "Fusion Genie",
          dis: <>One stop solution to order daily groceries online</>,
        },
      ],
    },
    {
      Year: "2022",
      details: [
        {
          position: "odd-div",
          logo: FmbLogo,
          title: "Fusion My Business",
          dis: <>One Spot to sell and buy anything online</>,
        },
        {
          position: "even-div",
          logo: FbookingLogo,
          title: "Fusion Booking",
          dis: <>Online Table Booking system</>,
        },
      ],
    },
  ];
  return (
    <>
      <section className="timeline">
        <Container>
          <Row>
            <Col xxl={12}>
              <h3>The Fusion Journey</h3>
              <div className="timeline-container">
                <div className="timeline-continue">
                  {JourneyDatas.map((JourneyData, index) => (
                    <>
                      <Row key={index}>
                        <Col xxl={12} md={12}>
                          <div className="timeline-year">
                            <p>
                              <span>{JourneyData.Year}</span>
                            </p>
                          </div>
                        </Col>
                      </Row>
                      {JourneyData.details.map((detail, index) => (
                        <>
                          <Row
                            key={index}
                            className={"align-items-center " + detail.position}
                          >
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                              <div className="left-div">
                                <Image src={detail.logo} fluid />
                              </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                              <div className="right-div">
                                <div className="timeline-box">
                                  <h5>{detail.title}</h5>
                                  <p>{detail.dis}</p>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      ))}
                    </>
                  ))}
                </div>
                <div className="current-time">
                  <div class="circle">2023</div>
                </div>
                <div className="comming-soon">
                  <div className="comming-soon-box">
                    <h5>Coming Soon</h5>
                    <p>Stay tuned for more updates</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default TimeLine;
