import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.scss";

import logo from "./img/footer-logo.png";
import Facebook from "./img/social/facebook.svg";
import Instagram from "./img/social/insta-fi.svg";
import Linkedin from "./img/social/Path-16988.svg";
import Twitter from "./img/social/twitter.svg";
import YoutubeIcon from "./img/social/youtube.svg";

const Footer = (params) => {
  const ProductMenus = [
    {
      Id: "M1",
      SubMenus: [
        {
          name: "FusionPOS",
          link: "https://www.fusionpos.co.uk/",
          target: "_blank",
        },
        {
          name: "Fusion Kitchen",
          link: "https://www.fusionkitchen.co.uk/",
          target: "_blank",
        },
        {
          name: "Fusion Suite",
          link: "https://crm.fusionsuite.co.uk/",
          target: "_blank",
        },
        {
          name: "Fusion Booking",
          link: "http://fusionbooking.co.uk/",
          target: "_blank",
        },
      ],
    },
    {
      Id: "M2",
      SubMenus: [
        {
          name: "Fusion Genie",
          link: "https://www.fusiongenie.co.uk/",
          target: "_blank",
        },
        {
          name: "Fusion Drive",
          link: "https://www.fusionpos.co.uk/driver-management",
          target: "_blank",
        },
        {
          name: "Fusion3",
          link: "https://www.fusion3.co.uk/",
          target: "_blank",
        },
        {
          name: "Fusion My business",
          link: "https://fusionmybusiness.com/",
          target: "_blank",
        },
      ],
    },
  ];
  const MenuLinks = [
    {
      name: "Career",
      link: "/career",
    },
    {
      name: "Blog",
      link: "/blog",
    },
    {
      name: "Press",
      link: "/press",
    },
    {
      name: "Contact Us",
      link: "/contactus",
    },
  ];

  return (
    <React.Fragment>
      <footer>
        <Container>
          <Row>
            <Col xl={2} sm={12} lg={2}>
              <div className="logo logo-footer">
                <img src={logo} alt="" />
              </div>
            </Col>
            <Col xl={6} sm={12} lg={7} md={8}>
              <Row className="justify-content-between">
                <Col xxl={7} sm={8} md={8} lg={8}>
                  <h4 className="footer-title">Products</h4>
                  <div className="footer-menu">
                    {ProductMenus.map((ProductMenu, index) => (
                      <ul key={index}>
                        {ProductMenu.SubMenus.map((SubMenu, index) => (
                          <li key={index}>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={SubMenu.link}
                            >
                              {SubMenu.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </Col>
                <Col xxl={4} sm={4} md={4} lg={4}>
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu">
                    <ul>
                      {MenuLinks.map((MenuLink, index) => (
                        <li key={index}>
                          <Link to={MenuLink.link}>{MenuLink.name}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>

            <FooterContact />
          </Row>
        </Container>
      </footer>
      <section className="copyright-sec">
        <Container>
          <Row>
            <Col xl={12}>
              <p>
                © {new Date().getFullYear()}
                <Link to={"/"}>
                  {" "}
                  Hardee Software Solutions Pvt. Ltd T/A Fusion Innovative
                </Link>{" "}
                All Rights Reserved. | &nbsp;
                <a
                  href="https://www.fusionkitchen.co.uk/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy - GDPR.
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Footer;

const FooterContact = (params) => {
  const SocialMedias = [
    {
      name: "Facebook",
      link: "https://www.facebook.com/Fusion-Innovative-102573004941728",
      img: Facebook,
    },
    {
      name: "Linkedin",
      link: "https://in.linkedin.com/company/fusion-innovative",
      img: Linkedin,
    },
    {
      name: "Instagram",
      link: "https://www.instagram.com/fusion_innovative",
      img: Instagram,
    },
    {
      name: "Twitter",
      link: "https://twitter.com/FusionInnovati2",
      img: Twitter,
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/@fusionpos",
      img: YoutubeIcon,
    },
  ];
  return (
    <React.Fragment>
      <Col xl={4} sm={12} lg={3} md={4}>
        <h4 className="footer-title">Connect with Us</h4>
        <div className="social-media">
          <ul>
            {SocialMedias.map((SocialMedia, index) => (
              <li key={index}>
                <a
                  href={SocialMedia.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={SocialMedia.img} alt="" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Col>
    </React.Fragment>
  );
};
