import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

// SCSS
import "./assets/style/form-modal.scss";

export default function FormFusion(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formState, setFormState] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    user_exp: "",
    user_position: props.apply,
    resume: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormState({ ...formState, resume: event.target.files[0] });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    axios
      .post("https://api.fusioninnovative.com/career", formState, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status === false) {
          alert(response.data.data);
        } else {
          console.log(response.data);
          alert("Form Submitted");
          setShow(false);
          setFormState({
            user_name: "",
            user_email: "",
            user_phone: "",
            user_exp: "",
            user_position: props.apply,
            resume: "",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Apply
      </Button>

      <Modal show={show} centered onHide={handleClose} id="form-modal">
        <Modal.Header closeButton>
          <Modal.Title>Personal Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={sendEmail}>
            <Form.Group className="mb-3">
              <Form.Label>Position you are applying for</Form.Label>
              <Form.Control
                className="default-input"
                type="text"
                readOnly
                defaultValue={props.apply}
                name="user_position"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Your Name"
                autoFocus
                required
                name="user_name"
                value={formState.user_name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email Address"
                required
                name="user_email"
                value={formState.user_email}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Control
                type="text "
                placeholder="Phone Number"
                required
                name="user_phone"
                value={formState.user_phone}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Years in Experience"
                required
                name="user_exp"
                value={formState.user_exp}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Upload Your CV</Form.Label>
              <Form.Control
                type="file"
                required
                name="resume"
                onChange={handleFileChange}
              />
            </Form.Group>
            <div className="upload-btn">
              <Button
                type="submit"
                disabled={
                  !formState.user_email.endsWith("@fusioninnovative.com")
                }
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
