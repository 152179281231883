import React from "react";
// images
import logo from "./img/logo.png";

// css
import "./navbar.scss";
// bootstrap
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
// Router
import { Link } from "react-router-dom";
// modal
//import Example from "../pages/component/modal/leadform";
// Component
// import FKAlert from "../common/fkalert/headeralert";

// Images
import FPOSLogo from "./img/Fusion-logos/fpos.svg";
import FKLogo from "./img/Fusion-logos/fk.svg";
import FSuitLogo from "./img/Fusion-logos/suite.svg";
import FBookingLogo from "./img/Fusion-logos/booking.svg";
import FGenieLogo from "./img/Fusion-logos/genie.svg";
import FDriveLogo from "./img/Fusion-logos/drive.svg";
import F3Logo from "./img/Fusion-logos/fusion3.svg";
import FMBLogo from "./img/Fusion-logos/fmb-icon.svg";

const Navigation = (params) => {
  const MenuLinks = [
    {
      MenuType: "Menu",
      MenuDetails: { name: "Home", link: "/" },
    },
    {
      MenuType: "Menu",
      MenuDetails: { name: "About Us", link: "/aboutus" },
    },
    {
      MenuType: "Menu",
      MenuDetails: { name: "Team", link: "/team" },
    },
    {
      MenuType: "DropDownMenu",
      DropDownTitle: "Products",
      MenuDetails: [
        {
          Id: "M1",
          SubMenus: [
            {
              name: "FusionPOS",
              img: FPOSLogo,
              link: "https://www.fusionpos.co.uk/",
              target: "_blank",
            },
            {
              name: "Fusion Kitchen",
              img: FKLogo,
              link: "https://www.fusionkitchen.co.uk/",
              target: "_blank",
            },
            {
              name: "Fusion Suite",
              img: FSuitLogo,
              link: "https://crm.fusionsuite.co.uk/",
              target: "_blank",
            },
            {
              name: "Fusion Booking",
              img: FBookingLogo,
              link: "http://fusionbooking.co.uk/",
              target: "_blank",
            },
          ],
        },
        {
          Id: "M2",
          SubMenus: [
            {
              name: "Fusion Genie",
              img: FGenieLogo,
              link: "https://www.fusiongenie.co.uk/",
              target: "_blank",
            },
            {
              name: "Fusion Drive",
              img: FDriveLogo,
              link: "https://www.fusionpos.co.uk/driver-management",
              target: "_blank",
            },
            {
              name: "Fusion3",
              img: F3Logo,
              link: "https://www.fusion3.co.uk/",
              target: "_blank",
            },
            {
              name: "Fusion My business",
              img: FMBLogo,
              link: "https://fusionmybusiness.com/",
              target: "_blank",
            },
          ],
        },
      ],
    },
    {
      MenuType: "Menu",
      MenuDetails: { name: "Career", link: "/career" },
    },
    {
      MenuType: "Menu",
      MenuDetails: { name: "Contact Us", link: "/contactus" },
    },
  ];

  return (
    <React.Fragment>
      <Navbar collapseOnSelect="true" expand="lg" sticky="top">
        {/* <FKAlert /> */}
        <Container>
          <div className="logo">
            <Nav.Link as={Link} to="/" eventKey={0}>
              <img src={logo} alt="" />
            </Nav.Link>
          </div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <div className="burger-menu">
              <div className="line-menu line-half first-line"></div>
              <div className="line-menu"></div>
              <div className="line-menu line-half last-line"></div>
            </div>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="">
              {MenuLinks.map((MenuLink, index) => (
                <>
                  {MenuLink.MenuType === "DropDownMenu" ? (
                    <>
                      <NavDropdown
                        key={index}
                        eventKey={index}
                        title={MenuLink.DropDownTitle}
                        id="collasible-nav-dropdown"
                      >
                        <div className="dmenubox">
                          {MenuLink.MenuDetails.map((MenuDetail, index) => (
                            <>
                              <ul key={index}>
                                {MenuDetail.SubMenus.map((SubMenu, index) => (
                                  <li>
                                    <NavDropdown.Item
                                      key={index}
                                      href={SubMenu.link}
                                      target={SubMenu.target}
                                      eventKey={MenuDetail.Id + index}
                                      className="drop-box"
                                    >
                                      <div class="menu-img-box">
                                        <img src={SubMenu.img} alt="" />
                                      </div>
                                      <div class="menu-txt-box">
                                        <span>{SubMenu.name}</span>
                                      </div>
                                    </NavDropdown.Item>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ))}
                        </div>
                      </NavDropdown>
                    </>
                  ) : (
                    <>
                      <Nav.Link
                        key={index}
                        eventKey={index}
                        as={Link}
                        to={MenuLink.MenuDetails.link}
                      >
                        {MenuLink.MenuDetails.name}
                      </Nav.Link>
                    </>
                  )}
                </>
              ))}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};
export default Navigation;
