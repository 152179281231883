import { Col, Container, Row } from "react-bootstrap";
import JoiningDatas from "./data";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./assets/style/inside.scss";
import { Link } from "react-router-dom";
import ErrorContent from "./error";

function List() {
  const { path } = useParams();
  const [jobValue, setJobValue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const foundValue = JoiningDatas.find((value) => value.path === path);

        if (foundValue) {
          setJobValue(foundValue);
          console.log(foundValue);
        } else {
          setError(<ErrorContent />);
        }
      } catch (err) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [path]);

  return (
    <section>
      <div className="main-section">
        <div className="internal-bg">
          <Container fluid>
            <Row className="align-items-center">
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="internal">
                  <h1 className="title">Guide me to a suitable job</h1>

                  <p className="cnt">
                    Guided search will enable you to find job opportunities
                    across functions and experience levels.
                  </p>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="career-img">
                  <img
                    src="/static/media/career.b7e4c8cbbbc896a6c9c2.webp"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {jobValue && (
        <div className="spectrum">
          <Container>
            <Row>
              <Col>
                <div className="headings">
                  <ul>
                    <li className="Post">Post</li>
                    <li className="Location">Location</li>
                    <li className="Exprience">Exprience</li>
                    <li className="Date">Date</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            {jobValue.Job.map((item, index) => (
              <Row key={index}>
                <Col>
                  <Link to={`/job-offer/${jobValue.path}/${item.Url}`}>
                    <div className="areas">
                      <div className="title">{item.Title}</div>
                      <div className="location">{item.Location}</div>
                      <div className="exp">{item.Exprience}</div>
                      <div className="date">{item.Date}</div>
                    </div>
                  </Link>
                </Col>
              </Row>
            ))}
          </Container>
        </div>
      )}
    </section>
  );
}

export default List;
