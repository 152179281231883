import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
// scss
import "./blog.scss";
//  Images
import MainBlogImg from "./img/mainblog.png";
import RightArrowIcon from "./img/right-arrow-acc.svg";

const Blog = (second) => {
  // const FkUrl = "https://fusioninnovative.com/";
  const BlogDetails = [
    {
      From: "Fusion Innovative",
      Date: "Dec 20, 2022",
      Title: "Our CEO Vision and Mission",
      Discription:
        "Create clear agreements vs. unspoken expectations. When you’re the Founder and CEO, it’s easy to lose sight of your role. The line between where you end and your business begins seems blurry.",
      Img: MainBlogImg,
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/season-special-dishes-to-try",
    },
    {
      From: "Fusion Kitchen",
      Date: "Dec 19th, 2022",
      Title: "This Season Special Dishes to Try",
      Discription:
        "Embellish your dining table with seasonal specials and a bucket load of comfort food to keep those winter blues at bay.",
      Img: "https://www.fusionkitchen.co.uk/public/v3/img/blog/blog39.jpg",
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/season-special-dishes-to-try",
    },
    {
      From: "FusionPOS",
      Date: "Dec 19th, 2022",
      Title:
        "Benefits of Having a Customer Loyalty Feature for a Takeaway / Restaurant",
      Discription:
        "Benefits of Having a Customer Loyalty Feature for a Takeaway / Restaurant.",
      Img: "https://www.fusionpos.co.uk/assets/img/blogs/FusionPOS-loyalty.jpg",
      ReadMore: "https://www.fusionpos.co.uk/loyalty-feature-blog",
    },
    {
      From: "Fusion Kitchen",
      Date: "Sep 3rd, 2022",
      Title:
        "7 Tips for Restaurants to Optimize Your Online Offerings and Boost Sales",
      Discription:
        "If you're a restaurateur looking to boost online sales, you're in luck! Online ordering...",
      Img: "https://www.fusionkitchen.co.uk/public/v3/img/blog/blog37.jpg",
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/7-tips-for-restaurants-to-boost-sales",
    },
    {
      From: "FusionPOS",
      Date: "Nov 5th, 2022",
      Title: "QR Code Food Ordering System for Takeaways / Restaurants",
      Discription:
        "QR Codes have become sort of a buzzword in the industry recently, but they are not a new technology,",
      Img: "https://www.fusionpos.co.uk/assets/img/blogs/FusionPOS-Qr-code.jpg",
      ReadMore: "https://www.fusionpos.co.uk/qr-code-food-ordering-system",
    },
    {
      From: "Fusion Kitchen",
      Date: "Aug 18th, 2022",
      Title: "6 World’s Delicious Dishes You Want To Try in 2022",
      Discription:
        "Has that ever happened to you that you wanted to try a new dish at a restaurant but ended.",
      Img: "https://www.fusionkitchen.co.uk/public/v3/img/blog/blog36.jpg",
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/6-worlds-delicious-dishes-you-want-to-try-in-2022",
    },
    {
      From: "FusionPOS",
      Url: "https://www.fusionpos.co.uk/",
      Date: "Oct 18th, 2022",
      Title:
        "Caller ID feature for online ordering system for Takeaways / Restaurants",
      Discription:
        "Build your customer database with the most prominent feature Caller ID.",
      Img: "https://www.fusionpos.co.uk/assets/img/blogs/FusionPOS-caller-ID.jpg",
      ReadMore:
        "https://www.fusionpos.co.uk/caller-id-feature-for-online-ordering-system",
    },
    {
      From: "Fusion Kitchen",
      Date: "June 23rd, 2022",
      Title:
        "Enjoy a Seamless Online Food Ordering Experience with Fusion Kitchen",
      Discription:
        "Online food ordering websites and apps have added great comfort to our lives by facilitating access.",
      Img: "https://www.fusionkitchen.co.uk/public/v3/img/blog/blog35.jpg",
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/online-food-ordering-experience",
    },
    {
      From: "FusionPOS",
      Date: "Sep 5th, 2022",
      Title: "5 Tips to Effectively Use Instagram for Restaurant Marketing",
      Discription:
        "In the wake of the COVID-19 pandemic, restaurants were forced to shift towards online marketing.",
      Img: "https://www.fusionpos.co.uk/assets/img/blogs/FusionPOS-Instagram-for-Restaurant-Marketing.jpg",
      ReadMore:
        "https://www.fusionpos.co.uk/instagram-for-restaurant-marketing",
    },
    {
      From: "Fusion Kitchen",
      Date: "May 05th, 2021",
      Title: "Margherita Pizza is named after a Queen",
      Discription:
        "If there is one thing in the world that everyone loves, it is pizza!. There is nothing more delicious than a freshly baked pizza right out of the oven.",
      Img: "https://www.fusionkitchen.co.uk/public/images/Blog28.jpg",
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/margherita-pizza-is-named-after-a-queen",
    },
    {
      From: "FusionPOS",
      Date: "Aug 16th, 2022",
      Title: "5 Easy Ways to Keep Up With Restaurant Industry Trends",
      Discription:
        "Keeping up with restaurant industry trends can feel like a full-time job. One day you're celebrating.",
      Img: "https://www.fusionpos.co.uk/assets/img/blogs/FusionPOS-Restaurant-Industry-Trends.jpg",
      ReadMore: "https://www.fusionpos.co.uk/restaurant-industry-trends",
    },
    {
      From: "Fusion Kitchen",
      Date: "June 2nd, 2021",
      Title:
        "Why Do People Prefer Online Food Ordering Rather Than Eating Out?",
      Discription:
        "When you are hungry and craving some delicious cuisine, what's the first thought that springs to your mind?",
      Img: "https://www.fusionkitchen.co.uk/public/images/blog29.jpg",
      ReadMore:
        "https://www.fusionkitchen.co.uk/blog/why-do-people-prefer-online-food-ordering-rather-than-eating-out",
    },
    {
      From: "FusionPOS",
      Date: "May 25th, 2022",
      Title:
        "How POS Data Analysis Can Help Improve Your Restaurant's Performance?",
      Discription:
        "The world is digitalizing at a fast pace and the hospitality industry is not far away in this race.",
      Img: "https://www.fusionpos.co.uk/assets/img/blogs/FusionPOS-Blog-EPOS2.jpg",
      ReadMore:
        "https://www.fusionpos.co.uk/how-to-improve-your-restaurant-performance",
    },
  ];

  return (
    <>
      <section className="blog-page">
        <section className="blog-section">
          <Container>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                <div className="main-blog">
                  <Image
                    src={BlogDetails[0].Img}
                    className="main-blog-img"
                    fluid
                  />
                  <span className="by-text">
                    By
                    <UrlData from={BlogDetails[0].From} />|{" "}
                    {BlogDetails[0].Date}
                  </span>
                  <h4 className="blog-title">{BlogDetails[0].Title}</h4>
                  <p className="blog-dis">{BlogDetails[0].Discription}</p>
                  <Link as={Link} to="/blog-details" className="read-btn">
                    Read More
                    <Image src={RightArrowIcon} alt="" />
                  </Link>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                <div className="sub-blog">
                  {BlogDetails.map((BlogDetail, index) => (
                    <>
                      {(index === 1) | (index === 2) ? (
                        <>
                          <div className="sub-blog-box">
                            <div className="content">
                              <span className="by-text">
                                By
                                <UrlData from={BlogDetail.From} />|{" "}
                                {BlogDetail.Date}
                              </span>
                              <h4 className="blog-title">{BlogDetail.Title}</h4>
                              <p className="blog-dis">
                                {BlogDetail.Discription}
                              </p>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                className="read-btn"
                                href={BlogDetail.ReadMore}
                              >
                                Read More
                                <Image src={RightArrowIcon} alt="" />
                              </a>
                            </div>
                            <div className="img">
                              <Image
                                className="img-fluid"
                                src={BlogDetail.Img}
                                alt=""
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Blog Section End */}
        <section className="sub-blog-section">
          <Container>
            <Row>
              {BlogDetails.map((BlogDetail, index) => (
                <>
                  {(index === 0) | (index === 1) | (index === 2) ? (
                    <></>
                  ) : (
                    <>
                      <Col
                        key={index}
                        xxl={6}
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        id={index}
                      >
                        <div className="blog-box">
                          <div className="content">
                            <span className="by-text">
                              By
                              <UrlData from={BlogDetail.From} />
                              {/* <a
                                target="_blank"
                                rel="noreferrer"
                                href={BlogDetail.Post[0].Url}
                              >
                                {BlogDetail.Post[0].From}
                              </a> */}
                              | {BlogDetail.Date}
                            </span>
                            <h4 className="blog-title">{BlogDetail.Title}</h4>
                            <p className="blog-dis">{BlogDetail.Discription}</p>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="read-btn"
                              href={BlogDetail.ReadMore}
                            >
                              Read More
                              <Image src={RightArrowIcon} alt="" />
                            </a>
                          </div>
                          <div className="img">
                            <Image
                              fluid
                              src={BlogDetail.Img}
                              alt=""
                              className="blog-img"
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  )}
                </>
              ))}
            </Row>
          </Container>
        </section>
      </section>
    </>
  );
};
export default Blog;

const UrlData = (props) => {
  return (
    <>
      {props.from === "FusionPOS" ? (
        <>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.fusionpos.co.uk/"
          >
            FusionPOS
          </a>
        </>
      ) : props.from === "Fusion Kitchen" ? (
        <>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.fusionkitchen.co.uk/"
          >
            Fusion Kitchen
          </a>
        </>
      ) : props.from === "Fusion Innovative" ? (
        <>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://fusioninnovative.com/"
          >
            Fusion Innovative
          </a>
        </>
      ) : null}
    </>
  );
};
