import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

// StyleSheet
import "./style/underconstruct.css";

// images
import UnderConstImg from "./images/under-construction/Fpos-under-construction.png";
import InstaImg from "./images/under-construction/insta.svg";
import LinkedinImg from "./images/under-construction/linkedin.svg";
import YoutubeImg from "./images/under-construction/youtube.svg";
import TwitterImg from "./images/under-construction/twitter.svg";
import FacebookImg from "./images/under-construction/facebook.svg";

// pages

const UnderConsruction = () => {
  const SocialMedias = [
    {
      img: FacebookImg,
      link: "https://www.facebook.com/FusionPOS/",
    },
    {
      img: TwitterImg,
      link: "https://twitter.com/Fusion_POS",
    },
    {
      img: YoutubeImg,
      link: "https://www.youtube.com/@fusionpos9803",
    },
    {
      img: InstaImg,
      link: "https://www.instagram.com/accounts/login/?next=/fusion.pos/",
    },
    {
      img: LinkedinImg,
      link: "https://www.linkedin.com/company/fusionposuk",
    },
  ];
  return (
    <React.Fragment>
      <section className="under-construct">
        <Container>
          <Row className="justify-content-around align-items-center">
            <Col xl={6} md={6}>
              <Image src={UnderConstImg} fluid />
            </Col>
            <Col xl={5} md={6}>
              <div className="under-inside">
                <h3>Under Construction</h3>
                <p>We're working hard to make this website available</p>
                <p>
                  We look forward to seeing you on our <br /> social media pages
                </p>
                <ul>
                  {SocialMedias.map((SocialMedia, index) => (
                    <li key={index}>
                      <a
                        href={SocialMedia.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src={SocialMedia.img} />
                      </a>
                    </li>
                  ))}
                </ul>
                <Link className="home-nav" to={"/"}>
                  Back to home
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default UnderConsruction;
