// *******~ Import ~******** //
// React
import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Assets
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { CgSoftwareUpload } from "react-icons/cg";

// Swiper
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination } from "swiper";

// Components

// CSS
import "./assets/style/style.scss";
// Images
import CareerImg from "./assets/img/career.webp";
import SearchIcon from "./assets/img/search-file.svg";

import QuotesIcon from "./assets/img/quotes.svg";

// REVIEW IMG
import DuraiImg from "./assets/img/review-img/Durairaj.jpg";
import AshokCImg from "./assets/img/review-img/Ashok-update.jpg";
// *******~ Import ~******** //
// JSON Data
import Datas from "./data";
const Career = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [matchedTopics, setMatchedTopics] = useState([]);

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    // Find all matching subtopics
    const matchingSubtopics = Datas.filter((subtopic) =>
      subtopic.Title.toLowerCase().includes(newSearchTerm.toLowerCase())
    );

    setMatchedTopics(matchingSubtopics);
  };
  const IconsDatas = [];

  return (
    <>
      <Helmet>
        <title>Career</title>
      </Helmet>
      <main className="career-page">
        <section className="career-slide">
          <Container>
            <Row className="align-items-center">
              <Col xxl={6} lg={6}>
                <h1>
                  Move Forward! Grab <br /> The Oppurtunities <br /> That You
                  Desire
                </h1>
                <div className="search">
                  <form action="#">
                    <Image src={SearchIcon} />
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Search By Keyword"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <button>Search Job</button>
                  </form>
                  {searchTerm && matchedTopics.length === 0 ? (
                    <>
                      <p style={{ color: "red", marginTop: "10px" }}>
                        Not Found
                      </p>
                    </>
                  ) : null}
                </div>
              </Col>

              <Col xxl={6} lg={6}>
                <Image src={CareerImg} fluid />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="icon-section">
          <Container>
            {searchTerm && matchedTopics.length > 0 ? null : (
              <>
                <Row className="justify-content-center">
                  {IconsDatas.map((IconsData, index) => (
                    <Col key={index} xxl={4} sm={7} md={6}>
                      <div className="icon-box">
                        <Image src={IconsData.icon} />
                        <p>{IconsData.text}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </>
            )}

            <section>
              <div className="job-container">
                <div className="title">Career Corner</div>
                <Container>
                  <Row>
                    <Col xxl={6} xl={6} lg={6}>
                      <div className="external-job">
                        <div className="cnt">
                          <div className="title">Job Opportunities</div>
                          <Link to="/job-offer">
                            <Button>View More</Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6}>
                      <div className="internal-job">
                        <div className="cnt">
                          <div className="title">Internal Job Postings</div>
                          <Link to="/internal-job-offer">
                            <Button>View More</Button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
          </Container>
        </section>

        <UploadCV />
        <EmpSays />
      </main>
    </>
  );
};
export default Career;

const Heading = (props) => {
  return (
    <>
      <div className="heading">
        <div className="Join-ico-box">
          <Image src={props.img} />
        </div>
        <h3>{props.title}</h3>
        <p>{props.dis}</p>
      </div>
    </>
  );
};

const EmpSays = (params) => {
  return (
    <>
      <section className="emp-says">
        <Container>
          <Row>
            <Col xxl={12}>
              <Heading title="What Our Employees Say" />
            </Col>
            <Col xxl={12}>
              <EmpReview />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const EmpReview = () => {
  const EmpReviewDatas = [
    {
      profile: DuraiImg,
      name: "Durairaj Chandrasekaran",
      desg: "Admin Manager",
      review: (
        <>
          Fantastic career and professional development oriented company.
          Professional Managers and Leaders taught a lot for your future.You can
          learn more and more work ethics.Nice Experience.
        </>
      ),
    },
    {
      profile: AshokCImg,
      name: "Ashok C",
      desg: "UI Developer",
      review: (
        <>
          Great environment to work with talented people, Supportive and
          friendly team. Happy to be a part of it
        </>
      ),
    },
  ];
  return (
    <>
      <>
        <Swiper
          slidesPerView={3}
          spaceBetween={50}
          grabCursor={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          speed={1000}
          centeredSlides={true}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="emp-review"
          breakpoints={{
            // when window width is >= 640px
            1: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            576: {
              slidesPerView: 2,
              centeredSlides: false,
              spaceBetween: 50,
            },
            768: {
              slidesPerView: 2,
              centeredSlides: false,
            },
            992: {
              slidesPerView: 3,
            },
          }}
        >
          {EmpReviewDatas.map((EmpReviewData, index) => (
            <SwiperSlide key={index}>
              <div className="emp-box">
                <div className="emp-heading">
                  <div className="profile">
                    <div className="img-div">
                      <Image src={EmpReviewData.profile} fluid />
                    </div>
                    <h3>{EmpReviewData.name}</h3>
                    <p>{EmpReviewData.desg}</p>
                  </div>
                  <Image src={QuotesIcon} alt="" />
                </div>
                <div className="review-text">
                  <p>{EmpReviewData.review}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    </>
  );
};

const UploadCV = (params) => {
  const [formState, setFormState] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    user_exp: "",
    user_position: "",
    resume: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormState({ ...formState, resume: event.target.files[0] });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    axios
      .post("https://api.fusioninnovative.com/career", formState, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: false,
      })
      .then((response) => {
        if (response.data.status === false) {
          alert(response.data.data);
        } else {
          console.log(response.data);
          alert("Form Submitted");
          setFormState({
            user_name: "",
            user_email: "",
            user_phone: "",
            user_exp: "",
            user_position: "",
            resume: "",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <section className="upload-cv">
        <Container>
          <Row className="justify-content-center">
            <Col xxl={8} xl={8} lg={8} sm={10} md={12}>
              <h2>Send us your CV</h2>
              <Form onSubmit={sendEmail}>
                <Row className="justify-content-center">
                  <Col xxl={6} md={6}>
                    <InputGroup className="mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Your Name*"
                        required
                        name="user_name"
                        value={formState.user_name}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col xxl={6} md={6}>
                    <InputGroup className="mb-4">
                      <Form.Control
                        type="email"
                        placeholder="Email Address*"
                        required
                        name="user_email"
                        value={formState.user_email}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col xxl={6} md={6}>
                    <InputGroup className="mb-4">
                      <div className="fixed_number">+91</div>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number*"
                        required
                        name="user_phone"
                        value={formState.user_phone}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col xxl={6} md={6}>
                    <InputGroup className="mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Years in Experience*"
                        required
                        name="user_exp"
                        value={formState.user_exp}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col xxl={6} md={6}>
                    <InputGroup className="mb-4">
                      <Form.Control
                        type="text"
                        placeholder="Position you are applying for"
                        required
                        name="user_position"
                        value={formState.user_position}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col xxl={6} md={6}>
                    <div className="input-group custom-file-button mb-4">
                      <label className="input-group-text" for="inputGroupFile">
                        <CgSoftwareUpload /> Upload Your CV
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile"
                        required
                        name="resume"
                        onChange={handleFileChange}
                      />
                    </div>
                  </Col>

                  <Col xxl={5}>
                    <center>
                      <Button type="submit">Submit</Button>
                    </center>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
