import { Col, Container, Row } from "react-bootstrap";
import "./assets/style/style.scss";

function ErrorContent() {
  return (
    <section className="contact-hr">
      <Container>
        <Row>
          <Col>
            <h5 className="error-title">
              Thanks for your interest with Fusion innovative Ltd. As of now,
              there are no openings in the concerned department,based on your
              experience, so kindly share your updated resume to this email for
              future reference.
              <a href="mailto:hr@fusioninnovative.com">
                hr@fusioninnovative.com
              </a>
            </h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ErrorContent;
