import React from "react";
import { Routes, Route } from "react-router-dom";

// Pages
import Home from "./pages/home";
import AboutUs from "./pages/aboutus";
import NotFound from "./pages/notfound";
import UnderConstruction from "./pages/underconstruct";
import Team from "./pages/team/team";
import Career from "./pages/career/career";
import Contact from "./pages/contact/contactus";
import Blog from "./pages/blog/blog";
import BlogDetails from "./pages/blog/blog-detail";
import Press from "./pages/press/press";
import JobOffer from "./pages/career/job-offer";
import InternalOffer from "./pages/career/internal-offer";
import List from "./pages/career/job-list";
import JobDetails from "./pages/career/jobdetails";
import InnerList from "./pages/career/internal-job-list";
import InnerJobDetails from "./pages/career/inner-job-details";

const Routing = (params) => {
  const RoutingPaths = [
    {
      url: "/",
      element: <Home />,
    },
    {
      url: "/aboutus",
      element: <AboutUs />,
    },
    {
      url: "/team",
      element: <Team />,
    },
    {
      url: "/career",
      element: <Career />,
    },
    {
      url: "/partner",
      element: <UnderConstruction />,
    },
    {
      url: "/contactus",
      element: <Contact />,
    },
    {
      url: "/blog",
      element: <Blog />,
    },
    {
      url: "/blog-details",
      element: <BlogDetails />,
    },
    {
      url: "/press",
      element: <Press />,
    },
    {
      url: "/job-offer",
      element: <JobOffer />,
    },

    {
      url: "/job-offer/:path",
      element: <List />,
    },
    {
      url: "/job-offer/:path/:Url",
      element: <JobDetails />,
    },
    {
      url: "/internal-job-offer",
      element: <InternalOffer />,
    },

    {
      url: "/internal-job-offer/:Inner",
      element: <InnerList />,
    },
    {
      url: "/internal-job-offer/:Inner/:Id",
      element: <InnerJobDetails />,
    },
    {
      url: "*",
      element: <NotFound />,
    },
  ];

  return (
    <React.Fragment>
      <Routes>
        {RoutingPaths.map((RoutingPath, index) => (
          <Route
            key={index}
            path={RoutingPath.url}
            element={RoutingPath.element}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

export default Routing;
