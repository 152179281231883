// CSS
import { Link } from "react-router-dom";
import "../../pages/career/assets/style/inside.scss";
// Assets
import { Container, Row, Col } from "react-bootstrap";
import Data from "./insidedata";

function InternalOffer() {
  return (
    <section>
      <div className="list-full">
        <div className="internal-bg">
          <Container fluid>
            <Row className="align-items-center">
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="internal">
                  <h1 className="title">Guide me to a suitable job</h1>

                  <p className="cnt">
                    Guided search will enable you to find job opportunities
                    across functions and experience levels.
                  </p>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="career-img">
                  <img
                    src="/static/media/career.b7e4c8cbbbc896a6c9c2.webp"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="functions">
          <Container>
            <Row>
              <Col>
                <div className="heading"> Career Advancement</div>
              </Col>
            </Row>
            <Row>
              {Data.map((item, index) => (
                <Col sm={6} md={4} lg={3} xl={3} xxl={3} key={index}>
                  <div className="box">
                    <img src={item.img} alt="" />
                    <div className="title">{item.title}</div>
                    <Link to={`/internal-job-offer/${item.Inner}`}>
                      <button>AVAILABLE JOBS </button>
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

export default InternalOffer;
