import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";

// CSS
import "./partners.css";

// Images Imported
import AwsImg from "./img/FusionPOS-Our-Partners-aws.png";
import BingImg from "./img/FusionPOS-Our-Partners-bing.png";
import BtImg from "./img/FusionPOS-Our-Partners-bt-logo.png";
import GoCardImg from "./img/FusionPOS-Our-Partners-gocardless.png";
import GoogleImg from "./img/FusionPOS-Our-Partners-google.png";
import StripeImg from "./img/FusionPOS-Our-Partners-stripe.png";
import StuartImg from "./img/FusionPOS-Our-Partners-stuart.png";
import YelpImg from "./img/FusionPOS-Our-Partners-yelp.png";
import SoloImg from "./img/FusionPOS-Solo-Press.png";

const OurPratners = (params) => {
  return (
    <React.Fragment>
      <section className="our-partners">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10} md={10}>
              <h3 className="home-title">Our Partners</h3>
              <div className="logo-all-first">
                <div>
                  <Image src={StuartImg} fluid />
                </div>
                <div>
                  <Image src={StripeImg} fluid />
                </div>
                <div>
                  <Image src={YelpImg} fluid />
                </div>
                <div>
                  <Image src={GoogleImg} fluid />
                </div>
                <div>
                  <Image src={GoCardImg} fluid />
                </div>
              </div>
              <div className="logo-all-second">
                <div>
                  <Image src={SoloImg} fluid />
                </div>
                <div>
                  <Image src={AwsImg} fluid />
                </div>
                <div>
                  <Image src={BtImg} fluid />
                </div>
                <div>
                  <Image src={BingImg} fluid />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default OurPratners;
