import React from "react";
import { Container, Row, Image } from "react-bootstrap";

// CSS
import "./life.css";

import RightArrow1 from "./img/Image1.png";
import RightArrow2 from "./img/Image2.png";
import RightArrow3 from "./img/Image3.png";
import RightArrow4 from "./img/Image4.png";

const LifeatFusion = (params) => {
  return (
    <React.Fragment>
      <Container className="lifeat">
        <Row className="justify-content-center align-items-center">
          <h3 className="home-title text-center">Life at Fusion</h3>

          <div className="d-flex f-dir">
            <div>
              <Image
                fluid
                className="right-arrow-list w-60"
                src={RightArrow1}
                alt=""
              />
              <p className="text-center sub-title">Values</p>
              <p className="text-center sub-des">
                A Company's value is the foundation for future innovation. It's
                the way you
              </p>
            </div>

            <div>
              <Image
                fluid
                className="right-arrow-list w-64"
                src={RightArrow2}
                alt=""
              />
              <p className="text-center sub-title">Life Style</p>
              <p className="text-center sub-des">
                Surround yourself with people who reflect values, goals and
                interests
              </p>
            </div>

            <div>
              <Image
                fluid
                className="right-arrow-list w-60"
                src={RightArrow3}
                alt=""
              />
              <p className="text-center sub-title">Experience</p>
              <p className="text-center sub-des">
                Our Brand will always be defined by our clients experience and
                Our Brand{" "}
              </p>
            </div>

            <div>
              <Image
                fluid
                className="right-arrow-list w-60"
                src={RightArrow4}
                alt=""
              />
              <p className="text-center sub-title">Team</p>
              <p className="text-center sub-des">
                Our Team produces the best results. Our goal is to provide you
                with a best
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default LifeatFusion;
