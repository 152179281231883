const InternalJoiningDatas = [
  // {
  //   Inner: "Inner-Developer",
  //   Job: [
  //     {
  //       Title: "Laravel Developer",
  //       Responsibilities: [
  //         "Must have hands-on experience in Laravel 8.0",
  //         "Must have hands-on experience in PHP",
  //         "Basic understanding of front-end technologies, Such as Javascript, CSS3, HTML5, Bootstrap.",
  //         "Familiarity with Mysql / SQL Database and their the declarative query language",
  //         "Proficient on API documentation tools/framework such as POSTMAN",
  //         "Work experience on Ajax, Json",
  //         "Good Knowledge on core php (OOPS) concepts",
  //         "Work experience in RestAPIs, XML",
  //         "Understanding of MVC design patterns",
  //         "Extensive experience with PHP in a LAMP environment.",
  //         "Must be technically strong in logical thinking",
  //         "Resolve cross-browser compatibility issues",
  //       ],
  //       KeySkills: [
  //         "AWS Knowledge",
  //         "React JS",
  //         "GIT",
  //         "Payment Gateway",
  //         "Third party API integrations.",
  //       ],
  //       SkillTags: [],
  //       Education: [],
  //       Exprience: "Min 2 years of Experience",
  //       Location: "Chennai",
  //       Shift: "Day Shift",
  //       Preferred: [],
  //       Phone: "+91 8838675423",
  //       Date: "12/10/2023",
  //       Id: "Inner-Laravel-Developer",
  //     },
  //   ],
  // },
  // {
  //   Inner: "Inner-Support",
  //   Job: [
  //     {
  //       Title: "Customer Support Executive",
  //       Responsibilities: [
  //         "To maintain high efficiency in handling escalated calls from the L1 team.",
  //         "To resolve tickets within agreed SLA of ticket volume and time",
  //         "To adhere to quality standards (voice and accent, Tech Monitoring), regulatory requirements and company policies",
  //         "To ensure positive customer experience and CSAT through defined resolution rate and minimum average handling time (AHT), rejected resolutions or Reopen Cases",
  //         "To update worklogs and follow shifts for escalation process and process compliance",
  //         "Work experience on Ajax, Json",
  //         "Handling all the queues efficiently and work towards case closure.",
  //       ],
  //       KeySkills: [
  //         "Excellent Communication with International Voice Support experience.",
  //         "Hands-on experience in Mobility/POS related Application & amp; Desktop/General system related issues",
  //         "Experience in Telecom will be an added advantage.",
  //       ],
  //       SkillTags: [],
  //       Education: [],
  //       Exprience: "Min 1 to 3 years of Experience",
  //       Location: "Chennai",
  //       Shift: "UK Shift",
  //       Preferred: [],
  //       Phone: "+91 8838675423",
  //       Date: "12/10/2023",
  //       Id: "Inner-Customer-Support-Executive",
  //     },
  //   ],
  // },
];
export default InternalJoiningDatas;
