const JoiningDatas = [
  {
    path: "Support",
    Job: [
      {
        Title: "Customer Support Executive",
        Responsibilities: [
          "To maintain high efficiency in handling escalated calls from the L1 team.",
          "To resolve tickets within agreed SLA of ticket volume and time",
          "To adhere to quality standards (voice and accent, Tech Monitoring), regulatory requirements and company policies",
          "To ensure positive customer experience and CSAT through defined resolution rate and minimum average handling time (AHT), rejected resolutions or Reopen Cases",
          "To update worklogs and follow shifts for escalation process and process compliance",
          "Work experience on Ajax, Json",
          "Handling all the queues efficiently and work towards case closure.",
        ],
        KeySkills: [
          "Excellent Communication with International Voice Support experience.",
          "Hands-on experience in Mobility/POS related Application & amp; Desktop/General system related issues",
          "Experience in Telecom will be an added advantage.",
        ],
        SkillTags: [],
        Education: [],
        Exprience: "Min 1 to 3 years of Experience",
        Location: "Chennai",
        Shift: "UK Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Customer-Support-Executive",
      },
      {
        Title: "Team Leader - Operations",
        Responsibilities: [
          "Monitoring and managing desktop support, and VIP support functions to ensure optimal service.",
          "Maintaining high performance levels for service-related processes, and implementing improvement activities wherever necessary",
          "Taking ownership of critical incidents, coordinating with resolution parties, and establishing effective communication between stakeholders for post-incident reviews.",
          "Manage installations, upgrades and configurations of hardware and software",
          "Ensuring that systems, procedures, and methodologies are in place to support outstanding service delivery",
          "Resolve issues escalated by technicians and engineers.",
          "Ensure data is handled, transferred or processed according to legal and company guidelines",
          "Provide support and guidance to stakeholders.",
          "Control costs and budgets regarding IT systems",
          "Developing a deep understanding of projects to gain insights into the scope of service delivery",
          "Taking accountability for service delivery performance, meeting customer expectations, and driving future demand",
          "Analyzing third-party as well as internal processes, and creating strategies for service delivery optimization",
          "Develop IT policies and practices",
        ],

        KeySkills: [
          "Proven experience as Team Leader",
          "Experience with system installation, configuration and analysis",
          "Knowledge of Desktop, Servers, networks and cloud computing",
          "Knowledge of data protection operations and legislation (e.g. GDPR)",
          "Leadership and organizational skills",
          "Ability to manage multiple projects",
          "Outstanding communication skills",
          "Problem-solving aptitude",
          "BSc/B-tech in Computer Science, IT or relevant field",
        ],
        SkillTags: [],
        Exprience: ["Min 7 years of Experience"],
        Education: [],
        Location: "Chennai",
        Shift: "UK Shift",
        Preferred: [
          "International Voice Process",
          "International BPO process",
          "Client Relationship Management",
        ],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Team-Leader-Operations",
      },
      {
        Title: "Operation Manager",
        Responsibilities: [
          "Monitoring and managing desktop support, and VIP support functions to ensure optimal service. • Maintaining high performance levels for service-related processes, and implementing improvement activities wherever necessary",
          "Taking ownership of critical incidents, coordinating with resolution parties, and establishing effective communication between stakeholders for post-incident reviews.",
          "Manage installations, upgrades and configurations of hardware and software",
          "Ensuring that systems, procedures, and methodologies are in place to support outstanding service delivery",
          "Resolve issues escalated by technicians and engineers.",
          "Ensure data is handled, transferred or processed according to legal and company guidelines",
          "Provide support and guidance to stakeholders.",
          "Control costs and budgets regarding IT systems",
          "Developing a deep understanding of projects to gain insights into the scope of service delivery",
          "Taking accountability for service delivery performance, meeting customer expectations, and driving future demand",
          "Analyzing third-party as well as internal processes, and creating strategies for service delivery optimization",
          "Develop IT policies and practices",
        ],

        KeySkills: [
          "Proven experience as IT Operations Manager/Service delivery manager",
          "Experience with system installation, configuration and analysis",
          "Knowledge of Desktop, Servers, networks and cloud computing",
          "Knowledge of data protection operations and legislation (e.g. GDPR)",
          "Leadership and organizational skills",
          "Ability to manage multiple projects",
          "Outstanding communication skills",
          "Problem-solving aptitude",
          "BSc/B-tech in Computer Science, IT or relevant field",
        ],
        SkillTags: [],
        Education: [],
        Exprience: ["Min 8 years of Experience"],
        Location: "Chennai",
        Shift: "UK Shift",
        Preferred: [
          "International Voice Process",
          "International BPO process",
          "Client Relationship Management",
        ],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Operation-Manager",
      },
    ],
  },
  {
    path: "Developer",
    Job: [
      {
        Title: "Laravel Developer",
        Responsibilities: [
          "Must have hands-on experience in Laravel 8.0",
          "Must have hands-on experience in PHP",
          "Basic understanding of front-end technologies, Such as Javascript, CSS3, HTML5, Bootstrap.",
          "Familiarity with Mysql / SQL Database and their the declarative query language",
          "Proficient on API documentation tools/framework such as POSTMAN",
          "Work experience on Ajax, Json",
          "Good Knowledge on core php (OOPS) concepts",
          "Work experience in RestAPIs, XML",
          "Understanding of MVC design patterns",
          "Extensive experience with PHP in a LAMP environment.",
          "Must be technically strong in logical thinking",
          "Resolve cross-browser compatibility issues",
        ],
        KeySkills: [
          "AWS Knowledge",
          "React JS",
          "GIT",
          "Payment Gateway",
          "Third party API integrations.",
        ],
        SkillTags: [],
        Education: [],
        Exprience: "Min 2 years of Experience",
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Laravel-Developer",
      },
      {
        Title: "Senior Android Developer",
        Responsibilities: [
          "Proficient of Java (Native Mobile App Development).",
          "Strong knowledge of Android SDK different versions of Android, Payment Gateway Integration, and how to deal with different screen sizes.",
          "Familiarity with REST &amp; RESTful APIs to connect Android applications to back-end services.",
          "Strong knowledge of Android UI design principles, patterns, and best practices.",
          "Experience with offline storage, threading, and performance tuning Ability to understand business requirements and translate them into technical requirements. Familiarity with cloud message APIs and push notifications.",
          "Understanding of Google’s Android design principles and interface guidelines.",
          "Proficient understanding of code versioning tools, such as Git.",
          "Team Handling is an added advantage.",
        ],
        KeySkills: [],
        SkillTags: [],
        Education: [],
        Exprience: "Min 4 years of Experience",
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Senior-Android-Developer",
      },
      {
        Title: "IOS Developer",
        Responsibilities: [
          "Language in Swift, and Cocoa Touch. - Familiarity with RESTful APIs to connect iOS applications to back-end services Publishing application on App Store.",
          "Designing and implementing application updates Auto layout design.",
          " MVVM design pattern Stripe payment integration Bitbucket Familiarity with cloud message APIs and push notifications",
          " Experience with embedded databases and other system datastores",
          " Familiarity with RESTful APIs to connect to back-end services",
          "Good sense of UI design and a user-oriented focus",
          "Knowledge of low-level C-based libraries is a plus",
          "Implementation of automated testing platforms and unit tests",
          "Knack for benchmarking and optimization",
          "Understanding of Apple’s design principles and interface guidelines",
          "Proficient understanding of code versioning tools {{e.g. Git,}",
          "Familiarity with continuous integration",
        ],
        KeySkills: [],
        SkillTags: [],
        Education: [],
        Exprience: ["Min 2 years of Experience"],
        Location: " Chennai",
        Shift: "Day Shift",
        Preferred: [
          "Payment Gateway",
          "Third Party API",
          "Google / Facebook API Integration",
          "Food website working experience",
          "Understanding of Restful API (JSON)",
          "Auto layout (Design)",
          "SQLite, Realm (DB)",
          "App Store submission.",
        ],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "IOS-Developer",
      },
      {
        Title: "Senior PHP Developer",
        Responsibilities: [
          "Must have hands on experience in Laravel 8.0",
          "Must have hands on experience in PHP",
          "Basic understanding of front-end technologies, Such as Javascript, CSS3, HTML5, Bootstrap. ",
          "Familiarity with Mysql / SQL Database and their declarative query language",
          "Proficient on API documentation tools/framework such as POSTMAN",
          "Work experience on Ajax, Json",
          "Good Knowledge on core php (OOPS) concepts",
          "Work experience in RestAPIs, XML",
          "Understanding of MVC design patterns",
          "Extensive experience with PHP in a LAMP environment.",
          "Must be technically strong in logical thinking",
          "Resolve cross-browser compatibility issues",
          "Team Leader experience is an added advantage",
        ],
        KeySkills: [],
        SkillTags: [],
        Education: [],
        Exprience: ["Min 5 years of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [
          "React JS",
          "GIT",
          "Payment Gateway",
          "Third party API integrations",
        ],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Senior-PHP-Developer",
      },
      {
        Title: "Android Developer",
        Responsibilities: [
          "Proficient of Java (Native Mobile App Development).",
          "Strong knowledge of Android SDK different versions of Android, Payment, Gateway Integration, and how to deal with different screen sizes.",
          "Familiarity with REST &amp; RESTful APIs to connect Android applications to back-end services.",
          "Strong knowledge of Android UI design principles, patterns, and best practices.",
          "Experience with offline storage, threading, and performance tuning Ability to understand business requirements and translate them into technical requirements. Familiarity with cloud message APIs and push notifications.",
          "Understanding of Google’s Android design principles and interface guidelines.",
          "Proficient understanding of code versioning tools, such as Git.",
        ],

        KeySkills: [],
        SkillTags: [],
        Education: [],
        Exprience: ["Min 2 years of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Android-Developer",
      },
      {
        Title: "React JS Developer",
        Responsibilities: [],

        KeySkills: [
          "Should have in-depth knowledge of JavaScript and React concepts, excellent front-end coding skills",
          "Extensive knowledge of HTML, CSS, JQuery, React.js or other js technologies",
          "Good understanding of integration of REST APIs &amp; Authentication services",
          "Knowledge with code versioning tools such as Git, Bitbucket",
          "Exposure to CI/CD, Agile Methodology, JIRA tools",
          "Strong problem solving and communication skills",
        ],
        SkillTags: [],
        Education: [],
        Exprience: ["Min 3 years of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "React-JS-Developer",
      },
      {
        Title: "Senior IOS Developer",
        Responsibilities: [
          "Language in Swift, and Cocoa Touch. - Familiarity with RESTful APIs to connect iOS applications to back-end services Publishing application on App Store.",
          "Designing and implementing application updates Auto layout design.",
          "MVVM design pattern Stripe payment integration Bitbucket Familiarity with cloud message APIs and push notifications",
          "Experience with embedded databases and other system datastores",
          "Familiarity with RESTful APIs to connect to back-end services",
          "Good sense of UI design and a user-oriented focus",
          "Knowledge of low-level C-based libraries is a plus",
          "Implementation of automated testing platforms and unit tests",
          "Knack for benchmarking and optimization",
          "Understanding of Apple’s design principles and interface guidelines",
          "Proficient understanding of code versioning tools {{e.g. Git,}",
          "Familiarity with continuous integration",
        ],

        KeySkills: [],
        SkillTags: [],
        Education: [],
        Exprience: ["Min 3 years of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [
          "Payment Gateway",
          "Third Party API",
          "Google / Facebook API Integration",
          "Food website working experience",
          "Understanding of Restful API (JSON)",
        ],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Senior-IOS-Developer",
      },
    ],
  },

  // Marketing
  {
    path: "Marketing",
    Job: [
      {
        Title: "Digital Marketing Executive",
        Responsibilities: [
          "Create, manage, recommend, and implement pay per click, display campaigns and other campaign formats through Google AdWords, Bing, YouTube, Facebook, Twitter and LinkedIn.",
          "Monitor campaign results, analyze key metrics, and optimize click-through and conversion rates.",
          "Measure ROI and KPIs and make necessary strategy adjustments if needed.",
          "Stay up to date with the latest digital marketing trends and best practices.",
          "Strong knowledge in SEO and App store optimization.",
          "Able to create quality backlinks, rank keywords in SERPs, increase organic traffic to the website.",
          "Brainstorm new and creative growth strategies",
          "To manage existing and launch new Social Media pages, create and organize content for distribution online platforms and community management.",
          "Collaborate with internal teams to create landing pages and optimize user experience.",
          "Manage campaign expenses, staying on budget &amp; estimating monthly costs.",
          "Effectively communicate results to management weekly.",
        ],
        KeySkills: [
          "Proven PPC &amp; SEO experience",
          "Solid understanding of performance marketing, conversion, and online customer acquisition",
          "In-depth experience with website analytics tools (e.g., Google Analytics, Google tag Manager, NetInsight, Ahrefs, SEMrush, WebTrends)",
          "Knowledge of ranking factors and search engine algorithms.",
          "Up-to-date with the latest trends and best practices in SEO &amp; PPC.",
          "Strong analytical skills and data-driven thinking. Well-versed in English.",
        ],
        SkillTags: [],
        Education: [
          "UG : B.E / B.Tech with M.B.A in Marketing from reputed Business School",
        ],
        Exprience: ["Min 1 year of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Digital-Marketing-Executive",
      },
      {
        Title: "Senior Digital Marketing Analyst",
        Responsibilities: [
          "Create, manage, recommend, and implement pay per click, display campaigns and other campaign formats through Google AdWords, Bing, YouTube, Facebook, Twitter and LinkedIn.",
          "Monitor campaign results, analyze key metrics, and optimize click-through and conversion rates.",
          "Measure ROI and KPIs and make necessary strategy adjustments if needed.",
          "Stay up to date with the latest digital marketing trends and best practices.",
          "Strong knowledge in SEO and App store optimization.",
          "Able to create quality backlinks, rank keywords in SERPs, increase organic traffic to the website.",
          "Brainstorm new and creative growth strategies",
          "To manage existing and launch new Social Media pages, create and organize content for distribution online platforms and community management.",
          "Collaborate with internal teams to create landing pages and optimize user experience.",
          "Manage campaign expenses, staying on budget &amp; estimating monthly costs.",
          "Effectively communicate results to management weekly.",
        ],
        KeySkills: [
          "Proven  PPC &amp; SEO experience",
          "Solid understanding of performance marketing, conversion, and online customer acquisition",
          "In-depth experience with website analytics tools (e.g., Google Analytics, Google tag Manager, NetInsight, Ahrefs, SEMrush, WebTrends)",
          "Knowledge of ranking factors and search engine algorithms.",
          "Up-to-date with the latest trends and best practices in SEO &amp; PPC.",
          "Strong analytical skills and data-driven thinking. Well-versed in English.",
        ],

        SkillTags: [],
        Education: [],
        Exprience: ["Min 4 years of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Senior-Digital-Marketing-Analyst",
      },
    ],
  },

  {
    path: "It-Infrastructure",
    Job: [
      {
        Title: "AWS cloud Engineer",
        Responsibilities: [
          "Strong expert level experience in Kubernetes and hands-on experience in Containers and Orchestration using Docker",
          "Expert in AWS cloud offerings and strong hands-on with AWS CLI",
          "Expert in Infra as a code using Terraform",
          "Must have expert level Experience on Toil automation to boost efficiency and innovation",
          "Must have expert level and hands-on experience with I-P-C (Incident, Problem &amp; Change) Management",
          "Must have managed stakeholders and related communications",
          "Must have Chaos testing experience or exposure",
          "Must have participated in Incident and Deployment Drills",
          "Working Experience on CICD Pipelines Configuration and Automation with tools like Git, Bitbucket, Jenkin, Octopus, ArgoCD",
          "Good Understanding of API Gateway like Kong, nginx based systems",
          "Hands-on Experience on Observability Configuration: Must be able to build &amp; configure customized metric-exporters/dashboards. OWCC/Prometheus/Grafana/ELK (Elasticsearch, Logstash, Kibana)",
        ],

        KeySkills: [
          "Ability to automate the Ops toil using Python/Go/Shell scripting.",
          "Intermediate level of skills on Linux OS, Shell Scripting, Bash scripting, cronjobs, networking (firewall, connectivity, routing, iptables, subnet config etc), ssh, sso",
          "Exposure to Cloud security / DevSecOps Practices",
          "Understanding of tools like Teleport for access related asks",
        ],
        SkillTags: [],
        Education: ["Any UG Degree from reputed Business School"],
        Exprience: ["Min 3 years of Experience"],
        Location: "Chennai",
        Shift: "Day Shift",
        Preferred: [],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "AWS-cloud-Engineer",
      },
    ],
  },

  {
    path: "Sales",
    Job: [
      {
        Title: "Senior Sales Executive",
        Responsibilities: [
          "Connect and engage with pre generated leads via outbound calls",
          "Reach out to new customers and explain to them the benefits of Naukri Fast Forward services",
          "Initiate repeat sales by follow up phone calls from existing customers",
          "Develop in-depth knowledge of products and services to make suitable recommendations based on customer needs",
          "Continually meeting or exceeding daily and monthly targets with respect to call volume and sales",
          "Achieve sales targets by new customer acquisition and ensure growth / revenue from existing customers",
          "Adhere to quality standards and processes within defined metrics",
        ],

        KeySkills: [
          "Good communication skills",
          "Active listening skills",
          "Strong convincing skills",
          "Client handling skills",
          "Ability to handle stress and rejection in soliciting clients",
          "Flexibility with rotation offs",
        ],
        SkillTags: [],
        Education: ["Any UG Degree from reputed Business School"],
        Exprience: ["Min 4 years of Experience"],
        Location: "Chennai",
        Shift: "UK Shift",
        Preferred: [
          "Prior experience in sales/business development/voice process will be preferred",
          "Solution oriented with effective problem solving skills",
          "Knowledge of various online portals will be an added advantage",
        ],
        Phone: "+91 8838675423",
        Date: "12/10/2023",
        Url: "Senior-Sales-Executive",
      },
    ],
  },
];
export default JoiningDatas;
