import React from "react";
const AddressMap = () => {
  return (
    <div className="google-map-code mlr-15">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.750011148997!
            2d80.2497371!3d13.0714358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x608aeb77b4038d79!
            2sFusion%20Innovative!5e0!3m2!1sen!2sin!4v1674813489884!5m2!1sen!2sin"
        width="1920"
        height="600"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
        className="mw-100"
        title="fusion-innovative"
      ></iframe>
    </div>
  );
};
export { AddressMap };
