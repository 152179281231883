import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import { Helmet } from "react-helmet";

// Style
import "./style/home.css";
// Pages
//import Review from "./component/review/review";
import OurClients from "./component/partners/clients";
import LifeatFusion from "./component/life/life";
import OurPratners from "./component/partners/partners";
import HomeBtn from "./component/homebtn";
// import BusinessNeeds from "./component/business-needs/business-needs";
//import BusinessNeedsV2 from "./component/business-needs-v2/business-needs";
// import Example from "./component/modal/leadform";
// Images Import
// import Homeslide from "./images/FPOSHomepage.svg";
import RightArrow from "./images/home/arrow.svg";
import Bsoftware from "./images/home/business-software.png";
//import EasyEPOSimg from "./images/home/Easy-Online-Ordering-System.png";
import Higherreachimg from "./images/home/Image21.png";
// import HomeSlideVid from "./images/home/FPOS-Homepage.mp4";
// import ManageRestaImg from "./images/home/Manage-your-Restaurant.png";
// import PlayStote from "./images/home/playstore.svg";
// import AppStore from "./images/home/appstore.svg";
// import RatingPOSImg from "./images/home/Rating-pos.png";
// import YtBtn from "./images/home/youtube.svg";
// import EposSetupImg from "./images/home/epos-img.png";
// import EposSetupImga from "./images/home/epos-img-0.png";
import Homescroll from "./images/home/homescroll.webp";

// Images
import FPOSLogo from "../header/img/Fusion-logos/fpos.svg";
import FKLogo from "../header/img/Fusion-logos/fk.svg";
import FSuitLogo from "../header/img/Fusion-logos/suite.svg";
import FBookingLogo from "../header/img/Fusion-logos/booking.svg";
import FGenieLogo from "../header/img/Fusion-logos/genie.svg";
import FDriveLogo from "../header/img/Fusion-logos/drive.svg";
import F3Logo from "../header/img/Fusion-logos/fusion3.svg";
import FMBLogo from "../header/img/Fusion-logos/fmb-icon.svg";

export default function Home() {
  return (
    <React.Fragment>
      <Slide />
      <EasyOnlineOrdering />
      {/* <BusinessNeeds /> */}
      {/* <BusinessNeedsV2 /> */}

      {/* <ManageResta />
      <EposImg />
      <Review />
      <RatingPOS /> */}
      <OurClients />
      <LifeatFusion />
      <OurPratners />
      <HighreReach />
    </React.Fragment>
  );
}

const Slide = (params) => {
  return (
    <React.Fragment>
      <section className="slider">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xl={6} md={12}>
              <div className="slide-content">
                <h3 className="home-title">
                  Fusion to Forge <br /> the Best!
                </h3>

                <p className="home-content">
                  Innovative and re-ignite the spark with Digital
                  <br /> transformation that helps develop the right business
                  <br /> culture and succeed.
                </p>
                {/* <HomeBtn title="FREE TRIAL" link={"/"} /> */}
              </div>
            </Col>
            <Col xl={6} md={12}>
              <div className="microsoft mq-height">
                <p class="marquee">
                  <img className="home-slide" src={Homescroll} alt="" />
                </p>
              </div>

              {/* <video src={HomeSlideVid} autoplay="autoplay" loop="true" /> */}
              {/* <video loop={true} muted={true} autoPlay playsInline={true}>
                <source src={HomeSlideVid} />
              </video> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

const EasyOnlineOrdering = () => {
  const EasyLists = [
    { title: "FusionPOS", logo: FPOSLogo },
    { title: "Fusion Kitchen", logo: FKLogo },
    { title: "Fusion Genie", logo: FGenieLogo },
    { title: "Fusion Suite", logo: FSuitLogo },
    { title: "Fusion Booking", logo: FBookingLogo },
    { title: "Fusion My Business", logo: FMBLogo },
    { title: "Fusion Drive", logo: FDriveLogo },
    { title: "Fusion3", logo: F3Logo },
  ];
  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <React.Fragment>
        <section className="easy-epos-system">
          <Container>
            <Row className="justify-content-around align-items-center">
              <Col xl={5} md={6}>
                <Image
                  fluid
                  className="esye-epos-img"
                  src={Bsoftware}
                  alt="Easy Online Ordering System"
                  title="Easy Online Ordering System"
                />
              </Col>
              <Col xl={6} md={6}>
                <h3 className="home-title">
                  Try the business software
                  <br /> used by over 2,000
                  <br /> companies
                </h3>

                <div className="d-flex wrap f-dir">
                  {EasyLists.map((EasyList, index) => (
                    <Col md={6} key={index}>
                      <p className="l-box">
                        <Image
                          fluid
                          className="product-logo"
                          src={EasyList.logo}
                          alt=""
                        />
                        {EasyList.title}
                        <Image
                          fluid
                          className="right-arrow-list"
                          src={RightArrow}
                          alt=""
                        />
                      </p>
                    </Col>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    </>
  );
};

const HighreReach = (params) => {
  // const HigherLists = [
  //   "Higher Reach - Minimal Effort",
  //   "Showcase your Brand",
  //   "Exclusive offers & discounts",
  // ];
  return (
    <React.Fragment>
      <section className="higher-reach">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col xl={5} md={6}>
              <h3 className="home-title text-white">
                Ready To KickStart,
                <br />
                With Innovative?
              </h3>
              <p className="home-content text-white">
                Join our family of 3000+ clients of all sizes who use
                <br />
                Fusion’ products to make it fast and easy to delight
                <br />
                their customers and employees.
              </p>

              {/* <a href={"/"} className="home-btn">
                Book your free demo now
                <img src={RightArrow} alt="" />
              </a> */}
              <HomeBtn title="Join With Us" link={"/career"} id="joinwith" />
            </Col>
            <Col xl={6} md={6}>
              <Image
                fluid
                src={Higherreachimg}
                className="higher-img"
                alt="Higher Reach With Fusion Kitchen"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

// const ManageResta = (params) => {
//   return (
//     <React.Fragment>
//       <section className="manage-resta">
//         <Container>
//           <Row className="  align-items-md-center align-items-xxl-center  justify-content-around">
//             <Col xl={4} md={6} sm={10} lg={5}>
//               <Image
//                 src={ManageRestaImg}
//                 fluid
//                 alt="Manage your Restaurant Anytime"
//               />
//             </Col>
//             <Col xl={5} md={6} lg={6}>
//               <h3 className="home-title">
//                 Manage your Restaurant Anytime! Anywhere!
//               </h3>
//               <p className="home-content">
//                 Manage your Takeaway Remotely Anywhere you are. You can access
//                 your reports, and promotions with Offers and discounts,
//                 Customize your Takeaway details, also manage payments.
//               </p>
//               <HomeBtn title="Book your free demo now" link={"/"} />
//               <div className="app-images">
//                 <a
//                   href="https://play.google.com/store/apps/details?id=in.fusionpos.fusionpartner"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <Image src={PlayStote} fluid />
//                 </a>
//                 <a
//                   href="https://apps.apple.com/in/app/fusion-partner/id1502807167"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <Image src={AppStore} fluid />
//                 </a>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </React.Fragment>
//   );
// };

// const RatingPOS = () => {
//   return (
//     <React.Fragment>
//       <section className="rated-pos">
//         <Container fluid>
//           <Row className="justify-content-xl-end justify-content-md-center align-items-center row">
//             <Col xl={5} xxl={4} md={11} lg={6}>
//               <h3 className="home-title">
//                 We’re rated excellent POS on Trustpilot & Google
//               </h3>
//               <p className="home-content">
//                 FusionPOS is rated excellent on Trustpilot for reliable our
//                 point of sale systems and customer support. Try our online
//                 ordering software for free and leave us a service review. Your
//                 feedback is of the utmost importance!
//               </p>
//               <HomeBtn title="Reviews us now" link={"/"} />
//             </Col>
//             <Col xl={6} md={11} lg={6}>
//               <div className="rate-pos-bg">
//                 <Image src={RatingPOSImg} className="btn-bg" fluid />
//                 <div className="wave">
//                   <span></span>
//                   <span></span>
//                 </div>
//                 <a
//                   data-fancybox
//                   href="https://youtu.be/ryHFhfh1hGo"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                 >
//                   <Image className="yt-btn" src={YtBtn} />
//                 </a>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </React.Fragment>
//   );
// };

// const EposImg = (params) => {
//   return (
//     <React.Fragment>
//       <section className="epos-setup">
//         <Container>
//           <Row className="justify-content-center">
//             <Col xl={8} md={12} sm={12}>
//               <Image src={EposSetupImga} fluid />
//             </Col>
//           </Row>
//         </Container>
//       </section>
//     </React.Fragment>
//   );
// };
