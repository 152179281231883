import React from "react";
// import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
//import RightArrow from "../images/home/right-arrow.svg";

const HomeBtn = (props) => {
  return (
    <Link to={props.link} className="home-btn" id={props.id}>
      {props.title}
      {/* <Image src={RightArrow} /> */}
    </Link>
  );
};
export default HomeBtn;
