import { Col, Container, Row } from "react-bootstrap";
import "../../pages/career/assets/style/inside.scss";
import { Link } from "react-router-dom";

import Data from "./insidedata";
const JoinDetails = () => {
  return (
    <section>
      <div className="list-full">
        <Container>
          <Row>
            {Data.map((item, index) => (
              <Col sm={6} md={4} lg={3} xl={3} xxl={3} key={index}>
                <div className="box">
                  <img src={item.img} alt="" />
                  <div className="title">{item.title}</div>
                  <Link to={`/job-offer/${item.path}`}>
                    <button>AVAILABLE JOBS </button>
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};
export default JoinDetails;
