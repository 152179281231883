import { Col, Container, Row, Image } from "react-bootstrap";
import JoiningDatas from "./data";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExprImg from "./assets/img/experience.svg";
import LocaImg from "./assets/img/location.svg";
import ShiftImg from "./assets/img/shift.svg";
import CallImg from "./assets/img/call.svg";
import "./assets/style/style.scss";
import FormPopup from "./form-modal";

function JobDetails() {
  const { Url } = useParams();
  const [Job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const foundJob = JoiningDatas.map((data) =>
          data.Job.find((job) => job.Url === Url)
        ).find((job) => job !== undefined);

        if (foundJob) {
          setJob(foundJob);
          console.log("Found Job:", foundJob);
        } else {
          setError("Job not found");
          console.log("Job not found");
        }
      } catch (error) {
        setError("An error occurred while fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Url]);

  return (
    <section>
      <div className="main-section">
        <div className="internal-bg">
          <Container fluid>
            <Row className="align-items-center">
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="internal">
                  <h1 className="title">Guide me to a suitable job</h1>

                  <p className="cnt">
                    Guided search will enable you to find job opportunities
                    across functions and experience levels.
                  </p>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="career-img">
                  <img
                    src="/static/media/career.b7e4c8cbbbc896a6c9c2.webp"
                    alt=""
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {Job && (
        <div className="job-background">
          <Container>
            <Row>
              <Col>
                <div className="Title">{Job.Title}</div>
                <div className="heading">Responsibilities</div>
                {Job.Responsibilities.map((Responsibilities, index) => (
                  <ul className="desc" key={index}>
                    <li>{Responsibilities}</li>
                  </ul>
                ))}
                {Job.KeySkills.length > 0 ? (
                  <>
                    <div className="heading">KeySkills</div>
                    <ul className="desc">
                      {Job.KeySkills.map((keySkills, index) => (
                        <li key={index}>{keySkills}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
                {Job.SkillTags.length > 0 ? (
                  <>
                    <div className="heading">SkillTags</div>
                    <ul className="desc">
                      {Job.SkillTags.map((skillTag, index) => (
                        <li key={index}>{skillTag}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
                {Job.Education.length > 0 ? (
                  <>
                    <div className="heading">Education</div>
                    <ul className="desc">
                      {Job.Education.map((education, index) => (
                        <li key={index}>{education}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
                <div className="job-details">
                  {Job.Exprience.length === 0 ? (
                    <></>
                  ) : (
                    <div className="detail-box">
                      <Image src={ExprImg} />
                      <div className="content">
                        <p>Experience</p>
                        <span>{Job.Exprience}</span>
                      </div>
                    </div>
                  )}
                  {Job.Location.length === 0 ? (
                    <></>
                  ) : (
                    <div className="detail-box">
                      <Image src={LocaImg} />
                      <div className="content">
                        <p>Work Location</p>
                        <span>{Job.Location}</span>
                      </div>
                    </div>
                  )}
                  {Job.Shift.length === 0 ? (
                    <></>
                  ) : (
                    <div className="detail-box">
                      <Image src={ShiftImg} />
                      <div className="content">
                        <p>Shift Timing</p>
                        <span>{Job.Shift}</span>
                      </div>
                    </div>
                  )}
                </div>
                {Job.Preferred.length > 0 ? (
                  <>
                    <div className="heading">Preferred</div>
                    <ul className="skill-tags">
                      {Job.Preferred.map((preferred, index) => (
                        <li key={index}>{preferred}</li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <></>
                )}
                <div className="title">Immediate Joiners Required</div>
                <div className="title">Talk to the HR</div>
                <div className="phone">
                  <a className="call" href={"tel:" + 918838675423}>
                    <img src={CallImg} alt="" />
                    <div className="telecom">+91 8838675423</div>
                  </a>
                </div>
                <FormPopup apply={Job.Title} />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </section>
  );
}

export default JobDetails;
