import React from "react";
import { Helmet } from "react-helmet";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

// Images
// import AboutSlider from "./images/about/about-slider.webp";
import VisionImg from "./images/about/vision.webp";
import MissionImg from "./images/about/mission.webp";
// import OurClientImg from "./images/about/our-clients.webp";
// import OurClientImgRes from "./images/about/client.png";

// Client Img
import ClientA from "./images/about/client-a.png";
import ClientB from "./images/about/client-b.png";
import ClientC from "./images/about/client-c.png";
import ClientD from "./images/about/client-d.png";
import ClientE from "./images/about/client-e.png";
import ClientF from "./images/about/client-f.png";
import ClientG from "./images/about/client-g.png";
import ClientH from "./images/about/client-h.png";
import ClientI from "./images/about/client-i.png";

// SCSS
import "./style/aboutus.scss";

// Component
import TimeLine from "./component/timeline/timeline";

const AboutUs = (params) => {
  return (
    <>
      <main id="about-page">
        <AboutSlider />
        <VisionMission />
        <OurClient />
        <TimeLine />
      </main>
    </>
  );
};
export default AboutUs;

function AboutSlider(params) {
  return (
    <>
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <section className="about-slider">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xxl={12}>
              <div className="content">
                <h3>About Us</h3>
                <p>
                  At Fusion, our mission is to guiding your <br /> business
                  through digital transformation
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="slider-dis">
        <Container>
          <Row>
            <Col xxl={12}>
              <p>
                We know the ever-changing landscape of digital transformation,
                and we’re experts in guiding your business through it. We take
                pride in listening <br /> to your goals, understanding your pain
                points, and developing a strategy designed just for you. By
                knowing your requirements, we can provide <br /> tailor-made
                solutions to fit your needs. Today is the day to start taking
                your Business to the next level - contact us today to see how!
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
const VisionMission = (params) => {
  return (
    <>
      <section className="vision-mission">
        <Container>
          <Row className="justify-content-center ">
            <Col xxl={5} xl={5} lg={5} md={6} sm={6}>
              <Image src={VisionImg} fluid />
            </Col>
            <Col
              xxl={{ span: 4, offset: 1 }}
              xl={{ span: 4, offset: 1 }}
              lg={{ span: 4, offset: 1 }}
              md={{ span: 5, offset: 1 }}
              sm={6}
            >
              <div className="content">
                <h3>Vision</h3>
                <p>
                  Using our immense passion to be the <br /> world’s leading
                  provider of innovative <br /> solutions to deliver a
                  remarkable
                  <br /> product and service globally! We are <br /> committed
                  to being responsible and to <br /> making a positive
                  difference in the <br /> business community.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-end">
            <Col xxl={5} xl={5} lg={5} md={5} sm={5}>
              <div className="content">
                <h3>Mission</h3>
                <p>
                  We strive to offer our customers <br /> innovative
                  technology-based products <br /> that stand out as unique in
                  terms of <br /> quality and service. We aim to be a <br />
                  resource for business owners as they <br />
                  start and grow their businesses.
                </p>
              </div>
            </Col>
            <Col
              xxl={{ span: 4, offset: 1 }}
              xl={{ span: 4, offset: 1 }}
              lg={{ span: 4, offset: 1 }}
              md={{ span: 4, offset: 1 }}
              sm={5}
            >
              <Image src={MissionImg} fluid />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

const OurClient = (params) => {
  const ClientLogos = [
    ClientA,
    ClientB,
    ClientC,
    ClientD,
    ClientE,
    ClientF,
    ClientG,
    ClientH,
    ClientI,
  ];
  return (
    <>
      <section className="our-clients">
        <Container fluid>
          <Row>
            <Col xxl={12} className="text-center">
              <h3>Our Clients</h3>
              <ul>
                {ClientLogos.map((ClientLogo, index) => (
                  <li key={index}>
                    <Image src={ClientLogo} fluid />
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
